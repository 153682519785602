import React from 'react';
import { Link } from 'react-router-dom';
function Header() {

  return (<>
    <div id="Header">
      <img className='sideImage left' src={require('../assets/image001.png')} alt="parentProgram" />
      <Link to='/home'>
        <img src={require('../assets/ezp_logo_approved_lg.png')} alt="EzParent" />
      </Link>
      <img className='sideImage right' src={require('../assets/image002.jpg')} alt="Childrens Institute" />
    </div>
  </>)
}

export default Header;