import React, { useEffect, useContext, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom'

import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import BackendService from '../../BackendService';

import { Context } from '../../context';
import './style.scss';

function Certificate() {
  let history = useHistory();
  let { id } = useParams();
  const context = useContext(Context);
  const [date, setDate] = useState('');
  useEffect(() => {
    let date = new Date();
    console.log(date);
    setDate(`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`)

  }, [])
  useEffect(() => {
    if (context.state.userToken) {
      asyncFunction()
    }
    // change Participant here
  }, [context.state.userToken])

  const asyncFunction = () => {
    if (!context.state.user_data) {
      console.log(context.state.user_data);
      context.getIndividual(id)
    }
  }
  const printDocument = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    const input = document.getElementById('divToPrint');
    html2canvas(input)
      .then((canvas) => {

        const imgData = canvas.toDataURL('image/png', 1);
        const pdf = new jsPDF({
          orientation: "landscape",
          unit: "px",

        });
        let pdfWidth = pdf.internal.pageSize.getWidth()
        let pdfHeight = pdf.internal.pageSize.getHeight()
        pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(`${context.state.participant_name}-certificate.pdf`);
      })
      ;
  }


  const setShowing = async () => {
    console.log(await context.state);

    let badges = await context.state.activeBadges;
    if (badges.length > 0) {
      badges.forEach((badgeName) =>
        badgeName ? document.getElementById(badgeName).classList.remove('hide') : null
      )
    }
    else {
      context.updateBadges(id, context.state.userToken)
    }
  }


  useEffect(() => {

    setShowing()

  }, [context.state])

  return (<>
    <div className="certificate-page">


      <div className='backHolder'>
        <button className='goBack' onClick={() => history.goBack()}>
          {`<Back`}
        </button>
      </div>
      <div className="mb5">
        <button className='print' onClick={() => printDocument()}>Download Certificate</button>
      </div>


      <div id="divToPrint" className="mt4"  >
        <img className='background' src={require("../../assets/Asset 1-100.jpg")} />
        <table cellpadding={0} cellspacing={0}>
          <tr>
            <td width={121} height={106} align="center">
              <img className='hide' alt='badge' id="badge_act_rewards" src={require("../../assets/badge_act_rewards.png")} />
            </td>
            <td width={121} height={106} align="center">

              <img className='hide' alt='badge' id="badge_mod1" src={require("../../assets/badge_mod1.png")} />
            </td>
            <td width={121} height={106} align="center">

              <img className='hide' alt='badge' id="badge_mod2" src={require("../../assets/badge_mod2.png")} />
            </td>
            <td width={121} height={106} align="center">

              <img className='hide' alt='badge' id="badge_mod3" src={require("../../assets/badge_mod3.png")} />
            </td>
            < td align="center" >

              <img className='hide' alt='badge' id="badge_mod4" src={require("../../assets/badge_mod4.png")} />
            </td >
            <td width={121} height={106} align="center" >

              <img className='hide' alt='badge' id="badge_mod5" src={require("../../assets/badge_mod5.png")} />
            </td >
            <td width={121} height={106} align="center" >

              <img className='hide' alt='badge' id="badge_mod6" src={require("../../assets/badge_mod6.png")} />
            </td >
            <td width={121} height={106} align="center" >

              <img className='hide' alt='badge' id="badge_act_wonder" src={require("../../assets/badge_act_wonder.png")} />
            </td >
          </tr >


          <tr>
            <td width={121} height={106} align="center">
              <img className='hide' alt='badge' id="badge_act_reframing" src={require("../../assets/badge_act_reframing.png")} />
            </td>
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >

              <img className='hide' alt='badge' id="badge_act_wizard" src={require("../../assets/badge_act_wizard.png")} />
            </td >
          </tr >

          <tr>
            <td width={121} height={106} align="center">
              <img className='hide' alt='badge' id="badge_act_pda" src={require("../../assets/badge_act_pda.png")} />
            </td>
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >

              <img className='hide' alt='badge' id="badge_act_tycoon" src={require("../../assets/badge_act_tycoon.png")} />
            </td >
          </tr >

          <tr>
            <td width={121} height={106} align="center">
              <img className='hide' alt='badge' id="badge_act_logic" src={require("../../assets/badge_act_logic.png")} />
            </td>
            <td width={121} height={106} align="center" >
            </td >
            <td width={100} height={106} align="center" className='adjust'  >
              <div className='participant_name'>
                {context.state.participant_name}
              </div>
            </td >
            <td width={121} height={106} align="center" >

            </td >
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >

              <img className='hide' alt='badge' id="badge_act_stress" src={require("../../assets/badge_act_stress.png")} />
            </td >
          </tr >

          <tr>
            <td width={121} height={106} align="center">
              <img className='hide' alt='badge' id="badge_act_iou" src={require("../../assets/badge_act_iou.png")} />
            </td>
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >

              <img className='hide' alt='badge' id="badge_act_sleuth" src={require("../../assets/badge_act_sleuth.png")} />
            </td >
          </tr >

          <tr>
            <td width={121} height={106} align="center">
              <img className='hide' alt='badge' id="badge_act_feelings" src={require("../../assets/badge_act_feelings.png")} />
            </td>
            <td width={121} height={106} align="center" >
              <div className="facilitator_name">
                {context.state.facilitator_name}
              </div>
            </td >
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" >
            </td >
            <td width={121} height={106} align="center" className='adjust' >
              <div className="date">
                {date}
              </div>
            </td >
            <td width={121} height={106} align="center" >

              <img className='hide' alt='badge' id="badge_act_routines" src={require("../../assets/badge_act_routines.png")} />
            </td >
          </tr >

          <tr>
            <td width={121} height={106} align="center">
              <img className='hide' alt='badge' id="badge_act_cia" src={require("../../assets/badge_act_cia.png")} />
            </td>
            <td width={121} height={106} align="center" >

              <img className='hide' alt='badge' id="badge_prof1" src={require("../../assets/badge_prof1.png")} />
            </td >
            <td width={121} height={106} align="center" >

              <img className='hide' alt='badge' id="badge_prof2" src={require("../../assets/badge_prof2.png")} />
            </td >
            <td width={121} height={106} align="center" >

              <img className='hide' alt='badge' id="badge_prof3" src={require("../../assets/badge_prof3.png")} />
            </td >
            <td width={121} height={106} align="center" >

              <img className='hide' alt='badge' id="badge_prof4" src={require("../../assets/badge_prof4.png")} />
            </td >
            <td width={121} height={106} align="center" >

              <img className='hide' alt='badge' id="badge_prof5" src={require("../../assets/badge_prof5.png")} />
            </td >
            <td width={121} height={106} align="center" >

              <img className='hide' alt='badge' id="badge_prof6" src={require("../../assets/badge_prof6.png")} />
            </td >
            <td width={121} height={106} align="center" >

              <img className='hide' alt='badge' id="badge_act_captain" src={require("../../assets/badge_act_captain.png")} />
            </td >
          </tr >
        </table >
      </div >
    </div>
  </>)
}

export default Certificate