import React, { useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Context } from "../context";

function GroupActivity() {
  let context = useContext(Context);
  let { group } = useParams();
  let history = useHistory();
  const toId = (route) => {
    history.push(route);
  };
  useEffect(() => {
    context.setParticipant(false);
    !context.state.groupData[group.toLowerCase()]
      ? history.goBack()
      : console.log(context.state.groupData);
  }, []);
  return (
    <>
      <div id="GroupActivity">
        <div>
          <button className="goBack" onClick={() => history.goBack()}>
            {`<Back`}
          </button>
        </div>
    
        <div className="contain">
      <h1>  
          {
            context.state.groupNames[context.state.groupLetters.indexOf(group)]
          }
          </h1>

          <div className="groupList">
            {context.state.groupData[group.toLowerCase()] ? (
              context.state.groupData[group.toLowerCase()].map((object) => {
                return (
                  <div className={"participant"}>
                    <span>
                      <h3>Participant:</h3>
                      <h2>
                        {object.parentName} - {object.participantId}
                      </h2>

                      <button
                        onClick={() =>
                          toId(`/IdActivity/${object.participantId}`)
                        }
                        className="certificate"
                      >
                        Details
                      </button>
                    </span>
                    <span>
                      <h3>Last Login:</h3>
                      <br />
                      <h2>
                        {object.lastlogin.substring(
                          0,
                          object.lastlogin.length - 9
                        )}
                      </h2>
                    </span>
                    <span>
                      <h3>Current Page:</h3>
                      <br />
                      <h2>{object.lastpage}</h2>
                      <br />
                    </span>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default GroupActivity;
