import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom'
import BackendService from '../BackendService';
import { Context } from '../context';
function IdActivity(props) {


  const [participantObject, setObject] = useState({});
  const [isLoading, setLoading] = useState(true);
  let { id } = useParams()
  let history = useHistory()
  const context = useContext(Context)


  useEffect(() => {
    if (context.state.userToken) {
      asyncFunction()
    }
    // change Participant here
  }, [context.state.userToken])

  let asyncFunction = async (token) => {
    if (await !context.getIndividual(id)) {
      history.push('/home');
    }
  }


  useEffect(() => {
    if (context.state.user_data) {
      let lastPage = context.state.user_data[0].lastpage;
      let rawlogin = context.state.user_data[0].lastlogin;
      let lastLogin = rawlogin.substring(0, rawlogin.length - 9);
      let arrayHold = []
      let badgeArray = []

      for (let x = 0; x < context.state.user_data.length; x++) {
        let badgeName = context.state.user_data[x].badge;
        let str = context.state.user_data[x].badgecompleted;
        let badgeComplete;
        if (str) {
          badgeComplete = str.substring(0, str.length - 9);
        }
        else {
          badgeComplete = str
        }

        let dataObject = {
          badgeName: badgeName,
          badgeComplete: badgeComplete
        }
        badgeArray.push(badgeName);
        arrayHold.push(dataObject);
      }
      let dataObject = {
        lastPage: lastPage,
        lastLogin: lastLogin,
        badges: arrayHold
      }
      context.setBadges(badgeArray);
      setObject(dataObject);
      setLoading(false)
    }

  }, [context.state.user_data])

  const goBack = () => {
    history.goBack()
  }
  return (<>{!isLoading ?
    <div className="IdActivity">
      <div>
        <button className='goBack' onClick={() => goBack()} >
          {`<Back`}
        </button>
      </div>
      <div>
        <h1>{context.state.user_data[0].parentName ? context.state.user_data[0].parentName : 'No Name'}</h1>
        <h2 >Participant Id: {id}</h2>
        <button onClick={() => history.push(`/certificate/${id}`)} className='certificate'>
          Certificate
      </button>
      </div>
      <div className="participant">
        <span><h2 >Last Login:</h2> <br /> {participantObject.lastLogin}</span>
        <span><h2>Current Page:</h2><br /> {participantObject.lastPage}</span>

        <ul className='badgeList'>
          <h2>Badges Earned: </h2>
          {participantObject.badges.map((item, index) => {
            return (<>
              <li key={index} >{index + 1}. {item.badgeName} -------------------
              <span>
                  Date completed: {item.badgeComplete}
                </span>
              </li>

            </>)
          })}
        </ul>
      </div>
    </div> :
    <>
      <div className='spinnerHolder'>
        <img className='spinner' src={require('../assets/spinner2.gif')} alt="loading" />
      </div>
    </>
  }
  </>)
}

export default IdActivity