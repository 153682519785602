import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import LoginService from "../LoginService";
import BackendService from "../BackendService";
import { Context } from "../context";

function Home() {
  const [id, changeId] = useState();
  const [idError, setIdError] = useState(false);
  let history = useHistory();
  let context = useContext(Context);

  useEffect(() => {
    if (!LoginService.isLoggedIn()) {
      window.location = "/";
    } else {
      if (
        context.state.groupData.a ||
        context.state.groupData.b ||
        context.state.groupData.c ||
        context.state.groupData.d ||
        context.state.groupData.e ||
        context.state.groupData.f ||
        context.state.groupData.g ||
        context.state.groupData.h ||
        context.state.groupData.i ||
        context.state.groupData.j ||
        context.state.groupData.k ||
        context.state.groupData.l ||
        context.state.groupData.m ||
        context.state.groupData.o
      ) {
        console.log("data persisted");
      } else {
        context.getGroups();
      }
    }
  }, [context.state.groupData]);

  const change = (e) => {
    setIdError(false);
    changeId(e.target.value);
  };

  const keyUp = (e) => {
    if (e.keyCode === 13) {
      toId();
    }
  };

  const toId = async () => {
    let response = await BackendService.getParticipant(
      id,
      context.state.userToken
    );
    if (await response[0]) {
      context.setParticipant(response, id);
      history.push(`/IdActivity/${id}`);
    } else {
      setIdError(true);
    }
  };

  const toGroup = async (group) => {
    history.push(`/GroupActivity/${group}`);
  };

  return (
    <>
      <div id="Home">
        {idError ? (
          <div className="errorMessage"> Invalid Participant Id</div>
        ) : (
          <></>
        )}

        <button id="logOut" onClick={() => context.logOut()}>
          Log Out
        </button>
        <span>
          <div className="inputSection">
            <h2>Participant ID lookup</h2>

            <input
              onKeyUp={(e) => keyUp(e)}
              className={idError ? "incomplete" : ""}
              placeholder="User ID"
              type="text"
              onChange={(e) => change(e)}
            />
            <br />
            <button onClick={() => toId()}>Submit</button>
          </div>
        </span>
        <div>
          <div className="errorMessage">
            {/* No Data */}
            {context.state.groupError}
          </div>

          <div className="groupBox">
            <h2>Group Lookup</h2>
            <br />
            <div className="buttonBox">

              {
                context.state.groupNames ?
                  context.state.groupLetters.map((item, i) => (
                    context.state.groupData[item] ? (
                      <button onClick={() => toGroup(item)}>
                        {context.state.groupNames[i]}
                      </button>
                    ) : (
                      <button>
                        <img
                          className="button-spinner"
                          src={require("../assets/spinner2.gif")}
                          alt="loading"
                        />
                      </button>
                    )
                  )) :
                  <></>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
