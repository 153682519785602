import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import BackendService from '../BackendService';
import LoginService from '../LoginService';
import { Context } from '../context';

function Login() {
  const [error, setError] = useState(false)
  let history = useHistory()
  let context = useContext(Context)

  useEffect(() => {
    document.addEventListener('keyup', keyup)
    return function cleanup() {
      document.removeEventListener('keyup', keyup);
    }
  })


  const keyup = (e) => {
    e.preventDefault();
    if (e.keyCode === 13) {
      Submit()
    }
  }
  const login = async (username, password) => {
    let response = await context.login(username, password)
    if (await response) {
      history.push('home')
    }
    else {
      setError(true);
    }
  }

  useEffect(() => {
    if (LoginService.isLoggedIn())
      window.location = '/home'
  }, [])


  const Submit = () => {
    let inputs = document.getElementsByTagName('input')
    let userName, password;
    if (inputs[0].value) {
      userName = inputs[0].value.trim();
    } else {
      inputs[0].className = 'incomplete'
    }
    if (inputs[1].value) {
      password = inputs[1].value;
    } else {
      inputs[1].className = 'incomplete'
    }
    userName && password ? login(userName, password) : setError(true)

  }


  return (<>
    <div id="Login" >
      <div className="inputSection">
        {error ?
          <p>There was a problem with your username or password</p> :
          <></>
        }
        <h2>Username</h2>
        <input type="text" />
        <h2>Password</h2>
        <input type="password" />
        <br />
        <button id='submit' onClick={() => Submit()}>
          submit
        </button>
      </div>
    </div>
  </>)
}
export default Login;