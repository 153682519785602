import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import IdActivity from './pages/IdActivity';
import './main.scss'
import GroupActivity from './pages/GroupActivity';
import Header from './components/Header';
import Login from './pages/Login';
import LoginService from './LoginService';
import { Provider } from './context';
import NotFound from './pages/NotFound';
import Certificate from './pages/Certificate/Certificate.jsx';


function App(props) {

  const [participantIDError, setError] = useState(false);
  const [isError, setStyleError] = useState(false);

  useEffect(() => {

    if (participantIDError) {
      window.location = '/home'
      setStyleError(true);
    }
  }, [participantIDError])

  return (<>
    <Provider>

      <Router>
        <Header />
        <img className='button-spinner' style = {{visibility: 'hidden'}} src={require('./assets/spinner2.gif')} alt="loading" />
        <img className='background' style = {{display: 'none'}} src={require("./assets/Asset 1-100.jpg")} />

        <Switch location={props.location}>

          <Route exact path='/'>
            <Login  />
          </Route>
          <Route>
            <Route path='/Home'>
              <Home  participantIDError={isError} />
            </Route>
            <Route path='/IdActivity/:id'>
              <IdActivity setError={(boolean) => setError(boolean)} />
            </Route>
            <Route path='/GroupActivity/:group'>
              <GroupActivity  />
            </Route>
            <Route path='/certificate/:id'>
              <Certificate />
            </Route>

          </Route>
          <Route component={NotFound} />
        </Switch>
      </Router>
    </Provider>
  </>
  );
}

export default App;
