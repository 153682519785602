import React from 'react'; 
import{Link } from 'react-router-dom';
function notFound (props) {

    return(
      <>
      <div id="notFound">
      Page Not Found
      </div>
      <Link>
        Go Home
      </Link>
      </>
    )
}

export default notFound