class BackEndServiceClass {
  async login(username, password) {
    let user = { username: username, password: password };
    const response = await fetch("/api/auth/login", {
      method: "POST",
      headers: { "Content-Type": "application/json;charset=utf-8" },
      body: JSON.stringify(user),
    });
    if (!response.ok) {
      return false;
    } else {
      let text = await response.text();
      return text;
    }
  }

  async getParticipant(id, token) {
    const response = await fetch(`/api/participant/${id}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.ok) {
      return false;
    } else {
      let participant = await response.json();
      return participant;
    }
  }

  
  async GetGroups(token) {
    const response = await fetch(`/api/group`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.ok) {
      let message = `${response.statusText} ${response.status}`;
      return message;
    } else {
      let group = await response.json();
      console.log(group);

      return group;
    }
  }

  async GetGroup(id, token) {
    const response = await fetch(`/api/Participant/Group/${id}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.ok) {
      let message = `${response.statusText} ${response.status}`;
      return message;
    } else {
      let group = await response.json();

      return group;
    }
  }
}

const BackEndService = new BackEndServiceClass();
Object.freeze(BackEndService);
export default BackEndService;
