import React, {
  Component
} from 'react';
import BackendService from './BackendService';
import LoginService from './LoginService';
export const Context = React.createContext();

export class Provider extends Component {
  state = {
    userIdError: false,
    groupError: false,
    activeBadges: [],
    groupIds: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'o'],
    groupData: {}
  }

  componentDidMount() {
    this.setState({
      userId: localStorage.getItem('username'),
      userToken: localStorage.getItem('userToken')
    })
  }

  login = async (username, password) => {
    let userToken = await BackendService.login(username, password)
    this.setUser(username, userToken);
    if (await userToken) {
      LoginService.login(userToken, username);
      return true;
    } else {
      return false;
    }
  }
getGroups = async () =>{

let names = await BackendService.GetGroups(localStorage.getItem('userToken'))
console.log(await names)
  let groupLetters = [];
  let groupNames = [];
  console.log(typeof names)
  if(await names){
    console.log(names)
    names.forEach((item)=>{
      console.log(item);
      groupLetters.push(item.groupletter.toLowerCase())
      groupNames.push(item.groupname)
    })
    
  }else{ 
    console.log(false)
  }
  this.setState({
    groupLetters: groupLetters, 
    groupNames: groupNames
  }, ()=>{
   this.setGroupData()
  })
}



  setGroupData = async () => {
    if (this.state.userId === 'admin') {
      this.state.groupLetters.forEach(async item => {
        let hold = this.state.groupData;
        let response = await BackendService.GetGroup(item, this.state.userToken)
        if (typeof response !== 'string') {
          hold[item] = await response
          this.setState({
            groupData: hold
          }, () => {
          })
        } else {
          this.setState({
            groupData: false,
            groupError: response
          })
        }
      })
    } else if (this.state.userId) {
      let last1 = this.state.userId.slice(-1);

      if (last1 !== 'h') {
        let hold = this.state.groupData;
        let response = await BackendService.GetGroup(last1, this.state.userToken);
        hold[last1] = await response
        if (typeof response !== 'string') {
          this.setState({
            groupData: hold
          }, () => {
          })
        } else {
          this.setState({
            groupData: false,
            groupError: response
          })
        }
      } else {
        let hold = this.state.groupData;
        let response1 = await BackendService.GetGroup('h', this.state.userToken)
        let response2 = await BackendService.GetGroup('g', this.state.userToken)
        hold.h = await response1
        hold.g = await response2
        if (typeof response1 !== 'string') {
          this.setState({
            groupData: hold
          }, () => {
          })
        } else {
          this.setState({
            groupData: false,
            groupError: response1
          })
        }
      }
    }

  }

  setBadges = (array) => {
    this.setState({
      activeBadges: array
    }, () => { })
  }

  logOut = async () => {
    LoginService.clearCookie();
    localStorage.removeItem('userToken');
    window.location.assign('/')
  }


  updateBadges = async (id, token) => {
    let array = await BackendService.getParticipant(id, token)
    let hold = []
    for (let i = 0; i < array.length; i++) {
      hold.push(array[i].badge)
    }

    this.setState({
      activeBadges: hold
    })
  }

  getIndividual = async (id) => {
    let response = await BackendService.getParticipant(id, this.state.userToken);
    if (await response[0]) {
      this.setParticipant(response, id)
      // set the participant equal to DB values
      return true
    }
    else {
      return false
    }
  }



  setParticipant = (data) => {
    
    data ?
      this.setState({
        user_data: data,
        participant_name: data[0].parentName,
        facilitator_name: data[0].facilitatorName
      }, () => {
        console.log(this.state)
      }) :
      this.setState({
        user_data: undefined,
        participant_name: undefined,
        facilitator_name: undefined
      })
  }

  setUser = (id, userToken) => {
    let userId = id.toLowerCase()
    this.setState({
      userId: userId,
      userToken: userToken
    })
  }
  setUserToken = (token) => {
    this.setState({
      userToken: token
    })
  }

  render() {
    return (<Context.Provider value={{
      state: this.state,
      setBadges: this.setBadges,
      updateBadges: this.updateBadges,
      setUser: this.setUser,
      setParticipant: this.setParticipant,
      login: this.login,
      setGroupData: this.setGroupData,
      getGroups: this.getGroups,
      logOut: this.logOut,
      setUserToken: this.setUserToken,
      getIndividual: this.getIndividual
    }
    } > {this.props.children}
    </Context.Provider>
    )
  }
}

export const Consumer = Context.Consumer;